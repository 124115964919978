/* eslint-disable @next/next/no-html-link-for-pages */
import { groq } from 'next-sanity';
import { mapBlocks, sanityClient, usePreviewSubscription } from 'utils/sanity';
import { useRouter } from 'next/router';
import React from 'react';
import RenderBlocks, { SanityBlock } from 'components/blocks/RenderBlocks';
import Main from 'components/layout/Main';
import { initializeApollo } from 'utils/apollo-client';
import { useAsync } from 'react-use';

const pageQuery = groq`
*[_type == "homepage" && _id == "homepage"][0] {
  blocks[] {
    ...,
    "tabs": tabs[]{
      ...,
      "products": products[]->{
        name,
        sku,
        "slug": slug.current,
        "photo": photos[0],
        categories[]->{
          "slug": slug.current,
        },
      },
    },
    "shortcuts": shortcuts[]{
      ...,
      "icon": icon->icon
    },
    "articles": articles[]->{
      _createdAt,
      title,
      lede,
      slug,
      image,
      author->,
      category->
    },
    "label": {
      "copy": label.copy,
      "icon": label.icon->icon,
      "hexCode": label.hexCode
    }
  }
}
`;

interface HomeProps {
  data: {
    page: {
      blocks: SanityBlock[];
    };
  };
}

export default function Home({ data }: HomeProps) {
  const router = useRouter();

  const previewEnabled = !!router.query.cmsPreview;

  const { data: page } = usePreviewSubscription(pageQuery, {
    initialData: data.page,
    enabled: previewEnabled,
  });

  const { value: mappedBlocks } = useAsync(async () => {
    if (!previewEnabled) {
      return page.blocks;
    }

    return mapBlocks(page.blocks);
  }, [previewEnabled, page.blocks]);

  return (
    <Main>
      <RenderBlocks
        blocks={(previewEnabled ? mappedBlocks : page.blocks) || []}
      />
    </Main>
  );
}

export async function getStaticProps({ preview = false }) {
  const apolloClient = initializeApollo();
  const page = await sanityClient.fetch(pageQuery);

  const blocks = await mapBlocks(page.blocks, apolloClient);

  return {
    revalidate: 1,
    props: {
      preview,
      data: {
        page: {
          blocks,
        },
      },
    },
  };
}
